@import "./../../color.scss";

.ant-layout-sider {
  background-color: transparent !important;

  .sider-logo {
    display: flex;
    background-color: $primary;
    color: $white-bg;
    text-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    line-height: 20px;
    padding: 0 36px 0 24px;
    font-size: 2.55rem;
    font-weight: 700;
    margin: -20px -15px 10px;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s all;
    height: 72px;
  }

  &.ant-layout-sider-collapsed {
    .mainlogo {
      justify-content: center;

      img {
        width: 0;
      }
    }
  }
}

.aside_slid {
  // border-right: 1px solid $border-color;
  display: flex;
  align-items: center;
  height: 100vh;

  .mainlogo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    img {
      max-width: 80%;
    }
  }

  .ant-layout-sider-children {
    background: $sidebar-background;
    padding: 20px 15px;

    .ant-menu-light {
      background-color: transparent;
    }

    .ant-menu-light {
      border: 0;
    }

    .ant-menu-item {
      padding: 0 10px !important;

      &:hover {
        .ant-menu-title-content {
          opacity: 0.9 !important;
        }
      }

      a {
        &.active {
          color: $white-bg;
          font-weight: 700;

          svg {
            color: $white-bg;
          }

          &::after {
            position: absolute;
            inset: 0;
            content: "";
            background: $primary;
            z-index: -1;

            //
          }
        }
      }

      // a{ position: absolute;
      //     left: 0; top: 0; width: 100%; padding-left: 50px; z-index: 999; border: 0px solid #f00;}
      .ant-menu-title-content {
        a {
          opacity: 0.7 !important;
          transition: all 50ms ease-in-out;

          &.active {
            opacity: 1 !important;
          }

          display: flex;
          align-items: center;
          justify-content: left;
          width: 200px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
        line-height: 40px;
        position: relative;
        top: 0px;
      }
    }

    .ant-menu {
      font-size: 0.85rem;
      letter-spacing: 0.025rem;
      // font-weight: 600;
    }

    .ant-menu-item-selected {
      font-weight: 700;

      .ant-menu-title-content {
        opacity: 1 !important;
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .ant-menu-item {
      // svg {
      //   width: 20px;
      //   height: 20px;
      //   margin-right: 12px;
      //   line-height: unset;
      //   position: relative;
      //   top: 0px;
      // }

      // .ant-menu-title-content{ display: none;}
    }
  }
}

.cir_logo {
  color: #ababab;
  position: relative;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 33px;
  line-height: 105px;

  &:before,
  &:after {
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center center;
  }

  &:before {
    box-shadow: inset 0 1px 0 rgba(151, 133, 223, 0.6),
      inset 1px 0 0 rgba(179, 169, 232, 0.6),
      inset 0 -1px 0 rgba(167, 155, 244, 0.6),
      inset -1px 0 0 rgba(158, 143, 229, 0.6);
    animation: rotate3 2s -0.2s linear infinite;
  }

  &:after {
    box-shadow: inset 0 1px 0 rgba(149, 180, 91, 0.6),
      inset 1px 0 0 rgba(149, 163, 61, 0.6),
      inset 0 -1px 0 rgba(149, 153, 61, 0.6),
      inset -1px 0 0 rgba(101, 123, 61, 0.6);
    animation: rotate4 1s -0.5s linear infinite;
  }
}

.logo_ai {
  color: #ababab;
  position: relative;
  width: 106px;
  height: 106px;
  text-align: center;
  font-size: 33px;
  line-height: 106px;

  img {
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .q_block {
    display: none !important;
  }

  // .aside_slid{ display: none;}
  .active_quick_block {
    width: 100%;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block !important;
  }

  .aside_slid {
    position: fixed !important;
    z-index: 10;
    height: 100vh;

    &.ant-layout-sider-collapsed {
      display: none !important;
    }
  }
}

.ant-tooltip-inner {
  a {
    svg {
      display: none;
    }
  }

  .active {
    svg {
      display: none;
    }
  }
}

.ant-menu-submenu-title {
  padding-left: 15px !important;
}

.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    padding-left: 15px !important;
    background-color: $white-bg;
    border-radius: 5px;

    &:hover {
      background-color: $primaryopi !important;
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    .ant-menu-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.ant-menu-item-active {
        .ant-menu-title-content {
          color: #7b61ff;
        }

        svg {
          color: #7b61ff;
          fill: #7b61ff;
        }
      }

      .ant-menu-title-content {
        color: #000000;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: #000;
        color: #000000;
        margin-right: 12px;
      }
    }
  }
}

.ant-menu .ant-menu-submenu .ant-menu-sub {
  margin-left: 20px;
  .ant-menu-item {
    font-size: 13px;
  }
}
