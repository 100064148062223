@import "./../../color.scss";

.aul_block {
  margin: 5px;
  padding: 15px;
  background-color: $sidebar-bg;
}
.app_block {
  .ant-card-body {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before {
      content: unset;
    }
    &::after {
      content: unset;
    }
  }
  img {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }
  .ant-tag {
    cursor: pointer;
    text-transform: capitalize;
    margin: 0;
  }
  .app_info {
    flex-direction: column;
    display: flex;
    text-transform: capitalize;
    font-weight: 600;
    small {
      opacity: 0.6;
      font-size: 0.75rem;
    }
  }
}

.mgrid {
  thead {
    th {
      padding: 8px 15px !important;
    }
  }
  tbody {
    td {
      padding: 8px 15px !important;
    }
  }
}

