@import "./color.scss";
@import "./theme/theme";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.025rem;
  // font-family: "Noto", sans !important;
  // font-family: 'Manrope', sans-serif !important;
  // font-family: "Lato", sans-serif !important;
  // -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, Inter, Segoe UI, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
  // overflow: auto !important;
  // height: 100vh;

  a {
    color: $primary;

    &:hover {
      color: $sidebar-background;
      text-decoration: none;
    }
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    background-color: $primary;
    background-image: none;

    &:first-child {
      margin-inline-start: 0px;
    }

    &.ant-switch {
      background-color: rgba(0, 0, 0, 0.25);

      &.ant-switch-checked {
        background-color: $primary;
      }
    }
  }

  .ant-layout,
  .ant-typography,
  .ant-menu,
  .ant-card,
  .ant-dropdown-menu,
  .ant-select,
  .ant-picker,
  .ant-picker-dropdown,
  .ant-select-dropdown,
  .ant-table,
  .ant-btn,
  .ant-tooltip,
  .ant-tag,
  .ant-tabs,
  .ant-col,
  .ant-tree,
  .ant-modal,
  .ant-input-number-group,
  .ant-input-number-input,
  .ant-input,
  .ant-descriptions {
    // font-family: 'Manrope', sans-serif !important;
    // font-family: "Inter", sans-serif !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 500;

    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  }

  .ant-layout {
    background-color: $sidebar-bg;
    overflow-x: hidden;
  }

  .ant-menu-light {
    color: $white-bg;

    .ant-menu-item {
      .ant-menu-item-icon+span {
        margin-inline-start: 0px !important;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      &:active {
        background-color: $primaryopi !important;
        color: $primary;
      }
    }

    .ant-menu-item-selected {
      background-color: $primary;
      color: $white-bg;
    }

    .ant-menu-submenu {
      &.ant-menu-submenu-open {
        div {
          background: #ffffff15;
          color: #7b61ff;

          &:hover {
            background: #7b61ff;
            color: #fff !important;
          }
        }
      }

      div {
        padding: 0 10px !important;
        transition: all ease-in-out 600ms;

        &:hover {
          color: #fff !important;

          .ant-menu-title-content {
            opacity: 0.4;
          }

          svg {
            opacity: 0.6;
          }
        }
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
  }

  .ant-card,
  .charts,
  .streamBlock {
    border: 0;
    box-shadow: $box_shadow;
  }

  .timetable,
  .in_bg,
  .tableContainer {
    border: 0 !important;
    box-shadow: $box_shadow;
    border-radius: 6px !important;
    background-color: $white-bg !important;
  }

  p {
    margin: 0px;
  }

  .item_center {
    align-items: center;
  }

  .space_between {
    justify-content: space-between;
  }

  .align_start {
    align-items: flex-start !important;
  }

  .lh_2 {
    padding: 5px 0;
  }

  .ant-btn {
    outline: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 13px;
    }
  }

  .ant-switch {
    outline: 0 !important;

    .ant-switch-inner-unchecked,
    .ant-switch-inner-checked {
      svg {
        margin-top: 0px;
        padding-top: 1px;
        font-size: 15px;
      }
    }
  }

  .anticon {
    vertical-align: unset !important;
  }

  .ant-form-item-label>label {
    font-weight: 600;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-modal-body {
    .ant-divider {
      margin: 15px 0 20px;
    }
  }

  // .edit{ transition: all ease-in-out 500ms; &:hover{ background-color: $primary-color !important;}}
  // .delete{transition: all ease-in-out 500ms; &:hover{ background-color: $danger-bg !important;}}
  .mobile {
    display: none;
  }

  .site-tree-search-value {
    color: #f50;
  }

  [data-theme="dark"] .site-tree-search-value {
    color: #ffb800;
  }

  .ant-tree-node-selected {
    color: $primary !important;
  }

  .ant-tree-title {
    font-weight: 600;
  }

  .ant-modal-confirm-content {
    margin-inline-start: 0px !important;
    max-width: 100% !important;
  }

  .ant-modal-confirm-body {
    display: flex;
  }

  // .ant-skeleton{ display: block  !important;}

  .switches-container {
    width: 11rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: $primaryopi;
    line-height: 2rem;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
  }

  .switches-container.s_icon {
    width: 7rem;
    height: 35px;
  }

  .switches-container.s_icon .switch div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
  }

  .switches-container.s_icon label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
  }

  .switches-container.s_icon svg {
    font-size: 1.2rem;
  }

  /* input (radio) for toggling. hidden - use labels for clicking on */
  .switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }

  /* labels for the input (radio) boxes - something to click on */
  .switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: $primary;
  }

  /* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
  .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.2rem;
    z-index: 3;
    transition: transform 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    /* transition: transform 0.4s; */
    border: 0px solid #f00;
  }

  /* switch box highlighter */
  .switch {
    border-radius: 4px;
    background: $white-bg;
    height: 100%;
  }

  /* switch box labels
  - default setup
  - toggle afterwards based on radio:checked status 
*/
  .switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: $primary;
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* slide the switch box from right to left */
  .switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
  }

  /* slide the switch box from left to right */
  .switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
  }

  /* toggle the switch box labels - first checkbox:checked - show first switch div */
  .switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
  }

  /* toggle the switch box labels - second checkbox:checked - show second switch div */
  .switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
  }

  --switches-bg-color: #7b61ff1a;
  --switches-label-color: #7b61ff;
  --switch-bg-color: white;
  --switch-text-color: #7b61ff;

  .ant-tooltip {
    font-size: 0.75rem;
  }

  // .ant-table-content {
  //   font-family: "Manrope", sans-serif !important;
  // }

  .ant-table-tbody {
    svg {
      font-size: 16px;
      opacity: 0.6;
      transition: all ease-in-out 600ms;

      &:hover {
        opacity: 1;
      }
    }

    .ant-avatar {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
    }
  }
}

// modal, drawer internal

.darkmode {
  .ant-modal-body {

    .ant-input,
    .ant-select-selector,
    .ant-input-number,
    .ant-input-number-input {
      background-color: $sidebar-bg-dark !important;
      color: $text-color-dark;
      border: 0;
    }

    .ant-input-number-handler {
      border-inline-start: 1px solid #7b61ff;
    }

    .ant-input-number-handler-down {
      border-block-start: 1px solid $sidebar-bg-dark;
    }

    .ant-input-number-handler-wrap {
      background-color: $switch-high-dark;
    }

    .ant-input-number-handler {

      .ant-input-number-handler-down-inner,
      .ant-input-number-handler-up-inner {
        color: #fff;
      }

      &:hover {

        .ant-input-number-handler-down-inner,
        .ant-input-number-handler-up-inner {
          color: $sidebar-bg-dark;
        }
      }
    }
  }

  .ant-drawer-close {
    color: $text-color-dark;
  }

  .chart_header,
  .number_block.in {
    svg {
      color: $switch-high-dark;
    }
  }

  .ant-table-tbody>tr {
    &:last-child {
      td {
        border-bottom: 1px solid $border-color-dark;
      }
    }

    td {
      border-top: 1px solid $border-color-dark;
    }
  }

  .ant-table-row:hover>td,
  .categorylist:hover {
    background-color: $overlay-dark !important;
  }
}

.ant-select-dropdown,
.ant-select-tree {
  @include theme() {
    background-color: theme-get("$dropdown-bg") !important;
  }

  .ant-select-tree-switcher {
    svg {
      @include theme() {
        color: theme-get("$text-color") !important;
      }
    }
  }

  .ant-select-tree-checkbox {
    .ant-select-tree-checkbox-inner {
      border: 1px solid;

      @include theme() {
        background-color: theme-get("$white-bg");
        border-color: theme-get("$border-color");
      }
    }
  }

  .ant-select-tree-checkbox-checked {
    .ant-select-tree-checkbox-inner {
      border: 1px solid;

      @include theme() {
        background-color: theme-get("$primary") !important;
        border-color: theme-get("$border-color");
      }
    }
  }

  .ant-select-item,
  .ant-select-tree-title {
    font-size: 0.8rem;

    @include theme() {
      color: theme-get("$text-color");
    }

    &.ant-select-item-option-selected {
      align-items: center;
      @include theme() {
        font-weight: 500;
      }
    }
  }
}

.ant-table,
.ant-select-selector,
.streamBlock,
.ant-picker,
.active_quick_block,
.in_bg,
.ant-card,
.ant-table-wrapper,
.ant-drawer-content,
.ant-modal-content,
.ant-modal-header {
  @include theme() {
    background-color: theme-get("$white-bg") !important;
    color: theme-get("$text-color");
  }
}

.ant-btn-default {
  @include theme() {
    background-color: theme-get("$white-bg") !important;
    color: theme-get("$text-color");
    // border-color: theme-get("$sidebar-bg");
  }
}

.ant-table-header>table,
.ant-table-header>table>thead>tr>th,
.ant-table-body>table>tbody>tr>td {
  @include theme() {
    border-inline-end: 1px solid theme-get("$sidebar-bg") !important;
  }
}

.ant-table.ant-table-bordered .ant-table-tbody>tr>td {
  @include theme() {
    border-bottom: 0px solid theme-get("$sidebar-bg") !important;
  }
}

.ant-table.ant-table-bordered>.ant-table-container,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border: 0 solid #000 !important;
}

.ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0px !important;
}

.ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px !important;
}

.ant-table-wrapper table,
.ant-table .ant-table-header {
  border-radius: 0 !important;
}

.ant-dropdown-menu,
.ant-dropdown-arrow::before,
.ant-dropdown {
  border-radius: 8px;

  @include theme() {
    background-color: theme-get("$white-bg") !important;
    color: theme-get("$text-color");
  }
}

.overlaylives {
  @include theme() {
    background-color: theme-get("$overlay") !important;
  }
}

.ant-layout,
.ant-dropdown-menu-item-divider,
.completed_block,
.step_block {
  @include theme() {
    background-color: theme-get("$sidebar-bg") !important;
  }
}

.ant-table-thead>tr>th {
  @include theme() {
    background-color: theme-get("$sidebar-bg") !important;
    color: theme-get("$text-color") !important;
  }
}

.ant-modal-title,
.ant-modal-close-x,
.ant-form,
.ant-select-selection-item-remove {
  @include theme() {
    color: theme-get("$text-color") !important;
  }
}

.ant-table-column-sort,
.ant-table-cell-fix-right,
.ant-table-cell-fix-left {
  @include theme() {
    background-color: theme-get("$selection") !important;
  }
}

.ant-table-row:hover>td,
.categorylist:hover {
  @include theme() {
    background-color: theme-get("$overlay");
  }
}

.categorylist.active,
.ant-pagination-item-active {
  @include theme() {
    background-color: theme-get("$active") !important;
  }
}

.ant-table-thead>tr>th {
  @include theme() {
    border-bottom: 1px solid theme-get("$border-color") !important;
  }
}

// .ant-typography,
h1,
.n_label,
.n_value,
.n_less,
.n_more,
.ant-select-selection-placeholder,
.ant-picker-input>input,
.sb_appname,
.ant-tabs,
.ant-pagination-item-link svg,
.p_icon_text,
.dark-mode,
.a_text,
.a_date_time,
.a_date_time span,
.a_name {
  @include theme() {
    color: theme-get("$text-color") !important;
  }
}

.ant-select-arrow svg,
.ant-picker-suffix svg,
.a_heading,
.text_profile,
.email_profile {
  @include theme() {
    color: theme-get("$text-color") !important;
  }
}

// input::placeholder,
// .colorlabel {
//   @include theme() {
//     color: theme-get("$text-color") !important;
//   }
// }

// .ant-btn svg{
//   @include theme() {
//     color: theme-get('$both-white') !important;
//   }
// }

.switches-container {
  @include theme() {
    background-color: theme-get("$swicth_light") !important;
  }
}

.switch {
  @include theme() {
    background-color: theme-get("$switch-high") !important;
  }
}

.switch div {
  @include theme() {
    color: theme-get("$swtich-text-color") !important;
  }
}

.charts {
  @include theme() {

    // .apexcharts-xaxis-tick,
    // .apexcharts-gridline,
    // .apexcharts-grid-borders line,
    .apexcharts-pie-area {
      stroke: theme-get("$sidebar-bg") !important;
    }

    // .apexcharts-text{
    //   fill: theme-get('$swtich-text-color') !important;
    // }
    .apexcharts-legend-text {
      color: theme-get("$text-color") !important;
    }
  }
}

// code {
//   // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//   //   monospace;
// }

.ant-modal-close {
  .ant-modal-close-x {
    svg {
      margin-top: 4px;
    }
  }
}

// .main-panel .w_table, .main-panel .tableContainer {
//   max-width: calc(100vw - 300px);
// }

.ant-layout-sider {
  position: relative;
  overflow: hidden;
  z-index: 1000;

  &.ant-layout-sider-collapsed {
    .ant-menu-item {
      svg {
        width: 20px;
        height: 20px;
        top: 10px;
        position: relative;
      }

      .ant-menu-title-content {
        text-indent: 999px;
      }
    }

    .ant-menu-submenu {
      width: 42px;

      div {
        // display: flex;
        // align-items: center;
        width: 100%;
        // background-color: #ffffff15;

        svg {
          width: 20px;
          height: 20px;
          top: 10px;
          position: relative;
        }

        .ant-menu-title-content {
          text-indent: 999px;
          visibility: hidden;
        }
      }

      &.ant-menu-item-active {
        div {
          background-color: #7b61ff;
        }

        .ant-menu-title-content {
          color: #7b61ff;
        }

        svg {
          color: #7b61ff;
          fill: #7b61ff;
        }
      }
    }

    .ant-layout-sider-children {
      position: fixed;
      top: 0px;
      width: 80px;
      // border-right: 1px solid $border-color;
    }
  }

  .ant-layout-sider-children {
    position: fixed;
    top: 0px;
    width: 220px;
    // border-right: 1px solid $border-color;
  }
}

.myzenModal {
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 20px 20px;
    border-bottom: 1px solid $border-color;
    margin: 0;
  }

  .ant-modal-body {
    padding: 0;
    min-height: calc(100vh - 300px);
  }

  .ant-tabs {
    display: flex;
    flex-direction: row;

    .ant-tabs-nav {
      width: 40%;
    }

    .ant-tabs-content-holder {
      width: 60%;
      background-color: $sidebar-bg;
      height: calc(100vh - 300px);
      overflow-x: hidden;
    }
  }

  .modal_section {
    display: grid;
    grid-template-columns: 40% 60%;

    .m_left {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .version {
        position: relative;
        margin-top: 15px;
        font-size: 0.75rem;
        font-weight: 700;
        opacity: 0.5;
      }

      .m_left_heading {
        width: 70%;
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 25px;
        text-align: center;
        line-height: 1.3;
        text-transform: capitalize;
        color: $text-hovercolor;
      }
    }

    .m_right {
      background-color: $sidebar-bg;
      height: calc(100vh - 300px);
      overflow-x: hidden;
      padding: 20px;

      .m_content {
        margin-bottom: 25px;

        h2 {
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 1.12rem;
          font-weight: 700;
          margin-bottom: 10px;
        }

        p {
          font-size: 0.85rem;
          font-weight: 500;
          margin-bottom: 10px;
          padding-left: 0px;
        }

        ul,
        ol {
          margin: 0;
          padding: 0;
          outline: 0;
          margin-left: 30px;
        }

        li {
          font-size: 0.85rem;
          margin-bottom: 10px;
          padding-left: 8px;

          b {
            font-weight: 700;
            color: $text-hovercolor;
          }
        }

        img {
          margin: 5px 0 10px;
        }
      }
    }

    .ant-segmented-item {
      margin: 0;
    }

    .ant-segmented {
      background: $primaryopi;
      color: $primary;

      .ant-segmented-item {
        transition: all ease-in-out 500ms;
        transform: scale(0.9);

        &.ant-segmented-item-selected {
          background-color: $primary;
          position: relative;
          width: 79px;

          a {
            color: #fff;
            position: absolute;
            width: 79px;
            left: 0;
            height: 100%;
            z-index: 3;
          }

          .ant-segmented-item-label {
            svg {
              width: 50px;
              height: 50px;
              fill: #fff;
              transform: scale(1);
            }
          }
        }
      }

      .ant-segmented-item-label {
        svg {
          width: 50px;
          height: 50px;
          fill: $primary;
        }
      }

      .ant-segmented-item-disabled {
        .ant-segmented-item-label {
          svg {
            width: 50px;
            height: 50px;
            fill: gray;
          }
        }
      }
    }
  }

  .os_requir {
    margin-top: 20px;
  }
}

// @media screen and (max-width: 768px) {
//   .auth_layout {
//     justify-content: center;
//     align-items: flex-start;
//     .signup_blcok {
//       max-width: 98%;
//       min-width: 98%;
//     }
//     .s_body {
//       padding: 40px !important;
//       .ant-row {
//         display: block;
//       }
//     }
//   }
// }

@media (min-width: 1280px) and (max-width: 1440px) {
  .aul_block {
    &.column_3 {
      grid-template-columns: 1fr 1fr !important;
    }
  }

  .app_charts {
    &.column_3 {
      grid-template-columns: 1fr !important;
    }
  }

  .appcolumn3 {
    grid-template-columns: 1fr 1fr !important;
  }

  .livcolumn4 {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .rcolumn4 {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .streamBlock {
    min-height: 163px;

    &.list {
      min-height: 63px;
    }
  }

  .number_stats .number_block .n_value {
    font-size: 1.4rem;
  }

  .number_stats .number_block .sn_value {
    font-size: 1rem;
    line-height: 1.3rem;
  }

  .number_stats .n_icon .less_i,
  .number_stats .n_icon .more_i {
    padding: 10px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .chartContainer {
    padding: 0 !important;
  }

  .dount_c {
    display: inline;

    // .apexcharts-canvas {
    //   width:200px!important;
    //   // height: 40% !important;
    //   svg{ width: 200px !important;}
    // }
    .apexcharts-legend {
      .apexcharts-legend-series {
        display: none !important;
      }
    }
  }

  .t_well {
    min-height: 270px !important;
  }
}

.timetable {
  width: calc(100vw - 300px);
  overflow: scroll;
  max-height: 600px;
  border: 1px solid $border-color;
  // min-height: unset;
}

.tableContainer {
  // overflow: scroll;
  // max-height: 400px;
  border: 1px solid $border-color;

  .ant-table-wrapper table {
    border-radius: 0 !important;
  }

  thead {
    th {
      padding: 8px 20px !important;
    }
  }

  tbody {
    .ant-table-row {
      td {
        padding: 8px 20px !important;
      }
    }
  }
}

.w_table,
.tableContainer {
  width: calc(100vw - 300px);
}

.layout_flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inner_layout {
  width: calc(100vw - 230px);
  // height:calc(100vh - 56px);
  padding-bottom: 30px;
  transition: 0.3s;
}

.silder-off {

  .w_table,
  .tableContainer,
  .timetable {
    width: calc(100vw - 280px);
  }

  .inner_layout {
    width: calc(100vw - 230px);
    // height:calc(100vh - 60px);
  }

  // .stats  { width: calc(100vw - 300px);}
  .notification {
    width: calc(100vw - 280px);
  }
}

.silder-on {
  .inner_layout {
    width: calc(100vw - 90px);
    // height:calc(100vh - 60px);
  }

  .notification {
    width: calc(100vw - 140px);
  }

  .w_table,
  .tableContainer,
  .task_table {
    width: calc(100vw - 140px);
  }

  .timetable {
    width: calc(100vw - 140px);
  }
}

.ant-table-measure-row {
  display: none;
}

.ant-drawer-content {
  &.userdetail_block {
    background: $sidebar-bg !important;

    .ant-drawer-header {
      background-color: #fff;
    }

    .ant-drawer-body {
      height: calc(100vh - 60px);
      overflow-x: hidden;
    }

    .ant-menu-light {
      &.user_menu {
        color: $text-hovercolor;
      }
    }

    .cardpadding_0 {
      overflow: hidden;

      .ant-card-body {
        padding: 0;
      }
    }
  }
}

// a:not([href]):not([tabindex]){
//   color: $primary;
// }
.ant-table-content .ant-table-tbody {

  .ant-table-cell:first-child,
  .ant-table-cell:last-child {
    background-color: #fff !important;
  }
}

.dount_c {
  display: inline;

  .apexcharts-legend {
    .apexcharts-legend-series {
      display: none !important;
    }
  }
}

.t_well {
  min-height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// @media only screen and (min-width: 1920px)
@media screen and (min-width: 1800px) {
  // html {
  //   .ant-layout-sider,
  //   .ant-layout-sider-children {
  //     // background: #f00 !important;
  //   }
  // }

  .appcolumn3 {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

@media screen and (min-width: 1268px) and (max-width: 1800px) {
  // html {
  //   .ant-layout-sider,
  //   .ant-layout-sider-children {
  //     // background: rgb(0, 38, 255) !important;
  //   }
  // }

  .appcolumn3 {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (max-width: 768px) {
  // html {
  //   .ant-layout-sider,
  //   .ant-layout-sider-children {
  //     // background: #f60 !important;
  //   }
  // }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  // -webkit-text-fill-color: green;
  // -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

* {
  -webkit-user-select: none;
  user-select: none;
}

/* The emerging W3C standard
    that is currently Firefox-only */
* {
  scrollbar-width: auto;
  scrollbar-color: rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.05);
}

*:hover {
  scrollbar-width: auto;
  scrollbar-color: rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.05);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar:hover {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  padding: 5px;
  border-top: 1px #f0f0f0 solid;
  //  border-radius:10px;
  // -webkit-box-shadow:0 0 6px gray inset;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  // border-radius: 10px;
  // border: 3px solid  rgb(255, 255, 255);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.05);
}

// *::-webkit-scrollbar-thumb:hover{
//  background-color:#f00;
// //  border:1px solid $indigo;
//  }
//  *::-webkit-scrollbar-thumb:active{
//  background-color:#f60;
// //  border:1px solid $indigo;
//  }

.app_name {
  padding: 0px 15px 10px;
  margin-top: -20px;
  font-weight: 0.85rem;
  font-weight: 700;
}

.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: $primaryopi;
  margin: 0px auto;
}

.determinate {
  position: relative;
  max-width: 100%;
  height: 100%;
  -webkit-transition: width 500ms ease-out 1s;
  -moz-transition: width 500ms ease-out 1s;
  -o-transition: width 500ms ease-out 1s;
  transition: width 500ms ease-out 1s;
  background-color: #03a9f4;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: $primary;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: $primary;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

.categorylist_block {
  max-height: calc(100vh - 250px);
  overflow-x: hidden;
}

.applist_block {
  max-height: 335px;
  overflow-x: hidden;
}

.late_batch {
  border: 1px solid $danger-bg;
  background-color: $danger-ops;
  color: $danger-bg;
  padding: 1px 5px;
  font-size: 0.7rem;
  margin-left: 10px;
  font-weight: 600;
  border-radius: 3px;
}

.icon_btn {
  svg {
    font-size: 17px !important;
    margin-right: 8px;
  }
}

.wellness_legend {
  margin: 60px 0 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    list-style: none;
    font-size: 0.75rem;
    font-weight: 600;
    padding-left: 15px;
    margin-right: 15px;
    position: relative;

    &:nth-child(1) {
      &::before {
        background-color: #7b61ff;
      }
    }

    &:nth-child(2) {
      &::before {
        background-color: #ffb800;
      }
    }

    &:nth-child(3) {
      &::before {
        background-color: #e2e1e8;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

.ant-pagination {
  padding-right: 20px;
}

.ant-select-arrow {
  cursor: pointer;
}

.ant-btn-icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
}

// zoom 150% or 125%

@media (max-width: 1300px) {

  //top container
  .stats.column4 {
    grid-template-columns: 2fr 2fr;
  }

  .lightmode .number_stats {
    margin-top: 20px;
  }

  .stats.column2per {
    grid-template-columns: 100%;
  }

  .stats.column2 {
    grid-template-columns: 1fr;
  }

  //  .f_end button span{background-color: red; }
  .filter_all .filter .filter_data .f_end .ant-picker {
    margin-right: 0;
    padding: 0px 4px;
  }

  .filter_all .filter .filter_data .ant-picker-range {
    padding-right: 0;
  }

  .filter_all .filter .filter_data .f_start {
    padding: px;
  }

  //Activity
  .stats.column3by2 {
    grid-template-columns: 85%;
  }

  //report
  .stats.rcolumn4 {
    grid-template-columns: 2fr 2fr;
  }

  c //application

  .stats.column3 {
    grid-template-columns: 40% 1fr;
  }

  .stats.appcolumn3 {
    grid-template-columns: 1fr;
  }

  //Livestearm
  // .filter_all .filter .filter_data{
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
  //nav dropdown

  .ml-3,
  .mx-3 {
    margin-left: 10px;
  }

  .mr-3,
  .mx-3 {
    margin-right: 2px !important;
  }

  .filter_all {
    flex-direction: column;
  }
}

// @media  (max-width: 1200px){
@media screen and (max-width: 1200px) {
  .stats.column4 {
    grid-template-columns: 2fr 2fr;
  }

  .lightmode .number_stats {
    margin-top: 20px;
  }

  .stats.column2per {
    grid-template-columns: 100%;
  }

  .stats.column2 {
    grid-template-columns: 1fr;
  }

  //  .f_end button span{
  //   background-color: red;

  //  }
  .stats.livcolumn4 {
    grid-template-columns: 2fr 2fr;
  }

  //Activity
  .stats.column3by2 {
    grid-template-columns: 85%;
  }

  //report
  .stats.rcolumn4 {
    grid-template-columns: 2fr 2fr;
  }

  //application
  .stats.column3 {
    grid-template-columns: 40% 1fr;
  }

  .stats.appcolumn3 {
    grid-template-columns: 1fr;
  }

  //productivity
  .stats.column3by2 {
    grid-template-columns: 100%;
  }

  .mr-3,
  .mx-3 {
    margin-right: 8px !important;
  }

  //setting scroll bar
  .ant-table-content {
    overflow-y: scroll;
  }
}

//sidebar
@media (max-width: 1500px) {
  .ant-layout-sider .ant-layout-sider-children {
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

.snapshot {
  width: 100% !important;
  height: 70vh !important;
}

.upload-avatar {
  border-radius: 3px;
  object-fit: cover;
}

.ant-tabs-tab-active {
  font-weight: 700 !important;
}

.ant-modal-body {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: end;
  }
}

.ant-modal-footer {
  display: flex;
  align-items: center;
}

.dd_type {
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.ant-popconfirm-buttons {
  @apply flex justify-end;
}

.inner_layout {
  padding: 20px 30px;

  @include theme() {
    background-color: theme-get("$sidebar-bg");
  }

  // .innercontent{padding-right: 20px; }
  .in_bg {
    border: 1px solid $border-color;
    border-radius: 5px;

    .ant-tabs-content-holder {
      width: 100%;
    }

    .ant-tabs {
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }

    .ant-divider {
      margin: 15px 0;
    }
  }

  .w_table {
    tbody {
      td {
        overflow: hidden;

        .healthy {
          background-color: $primary;
          display: grid;
          width: 60px;
          height: 52px;
          margin: -15px;

          span {
            display: none;
          }
        }

        .overworked {
          background-color: $primary-color;
          display: grid;
          width: 60px;
          height: 52px;
          margin: -15px;

          span {
            display: none;
          }
        }

        .overburdened {
          background-color: $primary-color;
          display: grid;
          width: 60px;
          height: 52px;
          margin: -15px;

          span {
            display: none;
          }
        }

        .underutilized {
          background-color: #e2e1e8;
          display: grid;
          width: 60px;
          height: 52px;
          margin: -15px;

          span {
            display: none;
          }
        }
      }
    }
  }

  .inner_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon_b {
      display: flex;
      align-items: center;

      .ant-avatar {
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 22px;
          height: 22px;
          margin-top: 1px;
        }
      }

      h1 {
        font-size: 25px;
        font-weight: 800;
        margin: 0;
      }
    }
  }

  .policylist {
    .ant-card-body {
      padding: 0px;
    }

    .policysearch {
      padding: 10px;
      border-bottom: 1px solid $border-color;
      margin-bottom: 0px;
    }

    .listpolicy {
      min-height: calc(100vh - 400px);
      overflow-x: hidden;

      .listdata {
        // padding: 8px 10px;
        border-bottom: 1px solid $border-color;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .p-list-text {
          padding: 12px 10px;
          cursor: pointer;
          display: grid;
          width: 100%;

          &:hover {
            color: $primary-color;
          }
        }

        .active {
          color: $primary-color !important;
        }

        .p-list-btn {
          display: flex;
          align-items: center;
          height: 38px;

          .btn-i {
            padding: 10px;
            cursor: pointer;
            height: 38px;
            display: grid;
            width: 100%;

            &.edit {
              &:hover {
                svg {
                  color: $primary;
                }
              }
            }

            &.delete {
              &:hover {
                svg {
                  color: $danger-bg;
                }
              }
            }

            svg {
              font-size: 16px;
            }

            &:hover {
              color: $white-bg;
            }
          }
        }
      }
    }
  }

  .lable-pun {
    min-height: calc(100vh - 345px);
    overflow-x: hidden;

    h2 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0px;
      padding: 15px;
      background-color: $grey_bg;
    }

    .ant-card-body {
      padding: 0;
    }

    .labledata {
      max-height: calc(100vh - 350px);
      overflow-x: hidden;

      .ant-radio-group {
        label {
          margin: 0 5px;
          border-radius: 5px;
          height: 28px;
          line-height: 26px;
          border: 1px solid $border-color;

          &:nth-child(1) {
            &.ant-radio-button-wrapper-checked {
              color: #fff;
              background-color: #37c977;

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #37c977;
              border-color: #37c977;
            }

            &:active {
              color: #fff;
              background-color: #37c977;
            }
          }

          &:nth-child(2) {
            &.ant-radio-button-wrapper-checked {
              color: #fff;
              background-color: #adb5bd;

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #adb5bd;
              border-color: #adb5bd;
            }

            &:active {
              color: #fff;
              background-color: #adb5bd;
            }
          }

          &:nth-child(3) {
            &.ant-radio-button-wrapper-checked {
              color: #fff;
              background-color: #ff647c;

              &:hover {
                color: #fff;
              }
            }

            &:hover {
              color: #ff647c;
              border-color: #ff647c;
            }

            &:active {
              color: #fff;
              background-color: #ff647c;
            }
          }

          &::before {
            background-color: unset;
          }
        }
      }

      .listlabel {
        border-bottom: 1px solid $border-color;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label-list-text {
          padding: 12px 10px;
          display: grid;
        }

        .label-list-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .pro {
          margin: 0 5px;
          border-radius: 5px;
          height: 28px;
          line-height: 26px;
          border: 1px solid $border-color;

          &:hover {
            .ant-typography {
              color: #37c977 !important;
            }

            border-color: #37c977;
          }

          &.active {
            .ant-typography {
              color: #fff !important;
            }

            background-color: #37c977 !important;
          }
        }

        .net {
          margin: 0 5px;
          border-radius: 5px;
          height: 28px;
          line-height: 26px;
          border: 1px solid $border-color;

          &:hover {
            .ant-typography {
              color: #adb5bd !important;
            }

            border-color: #adb5bd;
          }

          &.active {
            .ant-typography {
              color: #fff !important;
            }

            background-color: #adb5bd !important;
          }
        }

        .unpro {
          margin: 0 5px;
          border-radius: 5px;
          height: 28px;
          line-height: 26px;
          border: 1px solid $border-color;

          &:hover {
            .ant-typography {
              color: #ff647c !important;
            }

            border-color: #ff647c;
          }

          &.active {
            .ant-typography {
              color: #fff !important;
            }

            background-color: #ff647c !important;
          }
        }
      }
    }
  }

  .mapping {
    .ant-card-body {
      padding: 0;
    }

    .mappingsearch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      padding: 10px;
    }

    .listmapping {
      max-height: calc(100vh - 268px);
      overflow-x: hidden;

      .listmap {
        border-bottom: 1px solid $border-color;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-select-selector {
          __.ant-select-selection-item {
            background-color: $primary-bg-op;
          }
        }

        .map_it {
          display: flex;
          align-items: center;
        }

        .map-icon {
          display: flex;
          align-items: center;
          padding: 9px 10px;
          margin-right: 10px;
          border-right: 1px solid $border-color;
          background-color: $sidebar-bg;

          svg {
            margin-right: 10px;
          }
        }

        .maping-list-text {
          padding: 9px 10px;
          display: grid;
        }

        .maping-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 20px;
        }
      }
    }
  }

  .teamdetail {
    width: 100%;
    border: 0px solid $border-color;
    background-color: $sidebar-bg;
    overflow: hidden;
    border-radius: 5px;

    .ant-divider {
      margin-bottom: 0;
    }

    .ant-tabs-nav-wrap {
      padding-left: 15px;
    }

    .ant-tabs-content-holder {
      padding: 15px;
      padding-top: 0;
      padding-right: 0;
    }

    .groupde {
      h2 {
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .teamlist {
      margin: 0;
      padding: 0;
      margin-bottom: 15px;

      &.ingroup {
        max-height: 200px;
        overflow-x: hidden;

        li {
          background-color: $primaryopi;
          color: $text-color;
        }
      }

      &.notgroup {
        max-height: 300px;
        overflow-x: hidden;
      }

      li {
        border: 1px solid $border-color;
        margin: 4px 0;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        font-size: 0.8rem;
        font-weight: 600;
        width: 100%;
        border-radius: 5px;
        background-color: $white-bg;

        .ma_box {
          display: flex;
          align-items: center;

          .ant-avatar {
            margin-right: 10px;
          }
        }

        .m_email {
          font-size: 0.7rem;
          font-weight: 600;
          opacity: 0.6;
        }
      }
    }
  }

  .self-right-menu {
    border: 1px solid $border-color;
    background-color: $white-bg;
    padding: 5px;
    display: inline-block;
    border-radius: 5px;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 4px 10px;
        transition: all ease-in-out 600ms;
        border-radius: 3px;
        font-size: 0.8rem;
        font-weight: 600;

        a {
          display: flex;
          align-items: center;
          color: $text-color;

          svg {
            margin-right: 10px;
          }
        }

        &:hover {
          background-color: $grey_bg;
          color: $text-hovercolor;
        }
      }
    }
  }

  .pretty-json-container {
    max-height: calc(100vh - 400px);
    overflow-x: hidden;
    padding: 10px;
    border-radius: 8px;
  }

  .settings_comp {
    .setting_block {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
    }
  }
}

.auth_layout {
  // padding: 20px;
  background-color: $sidebar-bg;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .signup_blcok {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: 550px;

    &.onboarding_block {
      display: flex;
      flex-direction: column;
      max-width: 900px !important;
      min-width: 900px;
    }

    .w_50 {
      width: 100%;
    }

    .s_top {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 50px;

        img {
          height: 32px;
        }
      }

      .s_text {
        width: 60%;
        font-weight: 1.3rem;
        font-weight: 700;
      }

      .cir_logo {
        color: #ababab;
        position: relative;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 105px;
        height: 105px;
        text-align: center;
        font-size: 33px;
        line-height: 105px;

        &:before,
        &:after {
          content: "";
          border-radius: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform-origin: center center;
        }

        &:before {
          box-shadow: inset 0 1px 0 rgba(151, 133, 223, 0.6),
            inset 1px 0 0 rgba(179, 169, 232, 0.6),
            inset 0 -1px 0 rgba(167, 155, 244, 0.6),
            inset -1px 0 0 rgba(158, 143, 229, 0.6);
          animation: rotate3 2s -0.2s linear infinite;
        }

        &:after {
          box-shadow: inset 0 1px 0 rgba(149, 180, 91, 0.6),
            inset 1px 0 0 rgba(149, 163, 61, 0.6),
            inset 0 -1px 0 rgba(149, 153, 61, 0.6),
            inset -1px 0 0 rgba(101, 123, 61, 0.6);
          animation: rotate4 1s -0.5s linear infinite;
        }
      }

      .logo_ai {
        color: #ababab;
        position: relative;
        width: 106px;
        height: 106px;
        text-align: center;
        font-size: 33px;
        line-height: 106px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 50%;
        }
      }

      .logo_ai:after,
      .logo_ai:before {
        content: "";
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center center;
      }

      .logo_ai:after {
        box-shadow: inset 0 1px 0 rgba(30, 136, 229, 0.6),
          inset 1px 0 0 rgba(30, 106, 229, 0.6),
          inset 0 -1px 0 rgba(30, 76, 229, 0.6),
          inset -1px 0 0 rgba(30, 46, 229, 0.6);
        animation: rotate1 1s -0.8s linear infinite;
      }

      .logo_ai:before {
        box-shadow: inset 0 1px 0 rgba(249, 213, 61, 0.6),
          inset 1px 0 0 rgba(249, 183, 61, 0.6),
          inset 0 -1px 0 rgba(249, 153, 61, 0.6),
          inset -1px 0 0 rgba(249, 123, 61, 0.6);
        animation: rotate2 2s -0.5s linear infinite;
      }

      @keyframes rotate1 {
        0% {
          transform: rotateZ(0deg) scaleX(1) scaleY(1);
        }

        50% {
          transform: rotateZ(180deg) scaleX(0.95) scaleY(0.92);
        }

        100% {
          transform: rotateZ(360deg) scaleX(1) scaleY(1);
        }
      }

      @keyframes rotate2 {
        0% {
          transform: rotateZ(0deg) scaleX(1) scaleY(1);
        }

        50% {
          transform: rotateZ(-180deg) scaleX(0.9) scaleY(0.85);
        }

        100% {
          transform: rotateZ(-360deg) scaleX(1) scaleY(1);
        }
      }

      @keyframes rotate3 {
        0% {
          transform: rotateZ(0deg) scaleX(0.85) scaleY(1);
        }

        50% {
          transform: rotateZ(-180deg) scaleX(1) scaleY(1);
        }

        100% {
          transform: rotateZ(-360deg) scaleX(0.85) scaleY(1);
        }
      }

      @keyframes rotate4 {
        0% {
          transform: rotateZ(0deg) scaleX(0.95) scaleY(1);
        }

        50% {
          transform: rotateZ(-180deg) scaleX(1) scaleY(1);
        }

        100% {
          transform: rotateZ(-360deg) scaleX(0.95) scaleY(1);
        }
      }
    }

    .s_body {
      padding: 40px 50px 20px;
      background-color: $white-bg;
      border-radius: 8px;
      box-shadow: 0 20px 55px rgba(0, 0, 0, 0.05);
      margin: 30px 0;

      &.s_cong {
        text-align: center;

        .s_img {
          margin-bottom: 10px;
          text-align: center;

          img {
            max-width: 100%;
            margin: 0 auto;
          }
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 800;
          text-transform: capitalize;
          margin: 0 0 20px;
        }
      }

      .icon_auth {
        margin-bottom: 10px !important;
      }

      h3 {
        font-size: 1.3rem;
        font-weight: 800;
        text-transform: capitalize;
        margin: 0 0 20px;
      }

      h2 {
        font-size: 1.3rem;
        font-weight: 800;
        text-transform: capitalize;
        margin: 0 0 20px;
      }

      .sf_text {
        font-size: 16px;
        font-size: 0.85rem;
        font-weight: 600;
        color: $text-color;
        opacity: 0.6;
        margin-bottom: 20px;
        transition: all ease-in-out 600ms;

        &:hover {
          a {
            opacity: 1;
          }
        }
      }

      a {
        color: $primary;
        cursor: pointer;
      }

      .othersignup {
        margin-top: 30px;
        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        em {
          font-size: 0.75rem;
        }
      }

      .google_btn {
        padding: 0 20px 0 60px;
        border: 1px solid $border-color;
        background-color: $white-bg;
        border-radius: 8px;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        width: auto;
        height: 40px;
        margin: 10px auto 30px;
        overflow: hidden;
        font-size: 0.85rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $text-hovercolor;
        position: relative;
        transition: all ease-in-out 300ms;

        &:hover {
          transform: scale(1.01);
          // border: 1px solid $primary;
          // background-color: $primaryopi;
          color: $primary;
          font-weight: 700;
          box-shadow: 0 10px 20px -15px rgba(0, 0, 0, 0.05);
        }

        &::before {
          content: "";
          position: absolute;
          width: 40px;
          height: 40px;
          left: 10px;
          display: flex;
          align-items: center;
          background: url(./assets/images/g_login.png) no-repeat 50%;
          background-size: 50%;
        }
      }
    }

    .s_foot {
      padding: 0px;
      text-align: center;
      font-size: 0.8rem;
      display: flex;
      flex-direction: column;
    }

    .foot {
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        margin: 0;
        padding: 0;
        outline: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          list-style: none;
          margin: 0 15px;

          a {
            font-size: 0.7rem;
            font-weight: 600;
            color: $text-color;
            opacity: 0.6;
            transition: all ease-in-out 500ms;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    &.verification_box {
      .s_body {
        padding: 60px 50px;

        p {
          font-size: 0.8rem;
          opacity: 0.8;
        }

        .email {
          font-size: 1.2rem;
          font-weight: 700;
          margin: 5px 0;
        }

        .full_btn {
          margin-top: 30px;
        }
      }
    }
  }

  .setpass_blcok {
    padding: 0;
    position: relative;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;

    // border: 1px solid #f0f0f0;
    .s_top {
      position: absolute;
      top: 0;
      padding-top: 50px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .s_text {
        width: 80%;
        margin-top: 20px;
        text-align: center;
      }
    }

    .sbody {
      padding: 15px;
      position: relative;
      width: 100%;

      ul.items {
        position: absolute;
        // width: 30px;
        // height: auto;
        top: 50%;
        // left: -60px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        margin-top: 50px;
        padding: 0;
      }

      ul.items li {
        width: 8px;
        height: 8px;
        margin: 10px 20px;
        background: rgb(0, 0, 0);
        border-radius: 50%;
        opacity: 0.4;
        cursor: pointer;

        &::marker {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          background-color: transparent;
        }
      }

      ul.items li.active {
        opacity: 1;
      }

      form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        // width: 300px;
        // height: 60px;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
      }

      form .ant-input-group-addon {
        background-color: #f0f0f0;
      }

      form .steps {
        position: relative;
        width: 450px;
        height: 60px;
        // background: white;
        // border-radius: 3px;
        opacity: 0;
        transform: translateY(-120px) scale(0.9);
        // transform: scale(0.2);
        transition: all 0.4s ease-in-out;
        display: flex;
        align-items: center;
      }

      form .steps input,
      form .steps p {
        display: inline-block;
        // width: 200px;
        // margin-left: 50px;
        color: #333333;
        font-size: 16px;
        letter-spacing: 1px;
      }

      form .steps h2 {
        width: 100%;
        margin-top: 22px;
        text-align: center;
      }

      form .steps.thanks {
        text-align: center;
      }

      form .steps.thanks h2 {
        text-align: center;
        font-size: 1.25rem;
      }

      form .steps input {
        // height: 40px;
        // margin-top: 8px;
        // border: none;
        outline: none;
      }

      form .steps .icon {
        position: absolute;
        width: 45px;
        height: 40px;
        // top: 15px;
        border: 1px solid $sucess-opc;
        border-radius: 0 5px 5px 0px;
        border-left: 0;
        transition: all 0.4s ease;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      form .steps .icon i {
        position: absolute;
        display: block;
      }

      form .steps .icon i::before,
      form .steps .icon i::after {
        position: absolute;
        content: "";
      }

      form .steps .icon.left {
        left: 10px;
      }

      form .steps .icon.right {
        right: -40px;
        cursor: pointer;
        background-color: $primary;
        color: $white-bg;
      }

      form .steps .icon.button {
        display: grid;
        width: 45px;
        height: 40px;
      }

      form .steps .icon.button:hover {
        background: $sucess-bg;
        border-radius: 3px;
        transition: all 0.4s ease;
      }

      form .steps.enable {
        z-index: 1;
        opacity: 1;
        transition: all 0.5s ease-out 0.2s;
        transform: scale(1);
        // animation: enable 0.5s ease-out 0.2s;
      }

      form .steps.disable {
        opacity: 0;
        transition: all 0.3s ease-in;
        transform: translateY(120px) scale(0.9);
      }

      body.error .steps {
        transform-origin: 50% 100%;
        animation: error 0.3s ease-out;
      }

      @keyframes enable {
        0% {
          opacity: 0;
          transform: scale(0.2);
        }

        60% {
          transform: scale(1.1);
        }

        100% {
          opacity: 1;
          transform: scale(1);
        }
      }

      @keyframes error {

        0%,
        50%,
        100% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(-3deg);
        }

        75% {
          transform: rotate(3deg);
        }
      }

      /**
 * * Icons in CSS, long as f****
 * */
      .icon .arrow {
        width: 2px;
        height: 17px;
        top: 5px;
        left: 14px;
        background: #333333;
      }

      .icon .arrow::before {
        width: 6px;
        height: 6px;
        bottom: -1px;
        left: -3px;
        border-color: #333333;
        border-right: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(45deg);
      }

      .icon .user {
        width: 20px;
        height: 10px;
        bottom: 5px;
        left: 5px;
        box-shadow: 0 0 0 2px #333333 inset;
        border-radius: 6px 6px 3px 3px;
      }

      .icon .user::before {
        width: 10px;
        height: 10px;
        top: -9px;
        left: 5px;
        box-shadow: 0 0 0 2px #333333 inset;
        border-radius: 50%;
      }

      .icon .letter {
        width: 20px;
        height: 12px;
        top: 9px;
        left: 5px;
        box-shadow: 0 0 0 2px #333333 inset;
        border-radius: 3px;
      }

      .icon .letter::before,
      .icon .letter::after {
        width: 11px;
        height: 2px;
        top: 4px;
        background: #333333;
      }

      .icon .letter::before {
        left: 0;
        transform: rotate(30deg);
      }

      .icon .letter::after {
        right: 0;
        transform: rotate(-30deg);
      }

      .icon .lock {
        width: 20px;
        height: 16px;
        top: 9px;
        left: 5px;
        box-shadow: 0 0 0 2px #333333 inset;
        border-radius: 3px;
      }

      .icon .lock::before {
        width: 8px;
        height: 8px;
        top: -4px;
        left: 4px;
        border: 2px solid transparent;
        border-top: 2px solid #333333;
        border-right: 2px solid #333333;
        border-radius: 50%;
        transform: rotate(-45deg);
      }

      .icon .lock::after {
        width: 6px;
        height: 7px;
        top: 4px;
        left: 7px;
        box-shadow: 0 0 0 2px #333333 inset;
      }

      .icon .heart {
        width: 10px;
        height: 10px;
        top: 11px;
        left: 7px;
        background: #ff5233;
        transform: rotate(45deg);
      }

      .icon .heart::before,
      .icon .heart::after {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #ff5233;
      }

      .icon .heart::before {
        left: -6px;
      }

      .icon .heart::after {
        top: -6px;
      }
    }

    .foot {
      position: absolute;
      bottom: 0;
      padding-bottom: 50px;
    }
  }
}

.stats {
  display: grid;
  // align-items: center;
  column-gap: 20px;

  &.column5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  &.column4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.livcolumn4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.rcolumn4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.column3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.appcolumn3 {
    grid-template-columns: 1fr;
  }

  &.column3by2 {
    grid-template-columns: 25% 25% 1fr;
  }

  &.column2 {
    grid-template-columns: 1fr 1fr;
  }

  &.column2per {
    grid-template-columns: 25% 1fr;
  }

  &.column1 {
    grid-template-columns: unset;
  }

  &.column1by2 {
    grid-template-columns: 33% 1fr;
  }
}

.charts {
  margin-top: 20px;

  @include theme() {
    background-color: theme-get("$white-bg");
    border: 0px solid theme-get("$border-color");
  }

  border-radius: 6px;
  width: 100%;

  &.overflow {
    overflow: hidden;
  }

  &.marginT {
    margin-top: 0 !important;
  }

  .mosttable {
    border-top: 1px solid $border-color;

    .ant-progress {
      margin: 0;

      .ant-progress-outer {
        width: 90%;
      }

      .ant-progress-bg {
        height: 12px !important;
      }
    }

    thead {
      display: none;
    }

    tbody {
      td {
        &:last-child {
          width: 50%;
        }

        padding-left: 15px !important;
      }
    }
  }

  .apexcharts-legend-text,
  .apexcharts-text,
  .apexcharts-tooltip {
    font-family: "Lato", sans-serif !important;
  }

  .chartContainer {
    padding: 10px 10px;
  }

  .chart_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    margin-bottom: 20px;
    border-bottom: 0px solid $border-color;
    position: relative;
    z-index: 99;

    h2 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }

    svg {
      color: $text-color;
      opacity: 0.4;
      font-size: 18px;
      transition: all ease-in-out 600ms;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .all_tool_black {
    font-size: 0.75rem;

    .apexcharts-tooltip {
      background: transparent !important;
      border: 0 !important;
    }

    .bartooltip {
      padding: 8px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000cb;
        filter: blur(15px);
        -webkit-filter: blur(15px);
        z-index: -1;
      }
    }

    .w_data {
      font-size: 0.75rem;
      display: flex;
      padding: 0 10px 8px;

      .colordot {
        position: relative;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 5px;
      }

      .w_value {
        font-weight: 800;
        font-size: 0.75rem;
        margin-left: 5px;
      }
    }
  }

  .all_tool {
    font-size: 0.75rem;

    .apexcharts-tooltip {
      background: transparent !important;
      border: 0 !important;
    }

    .bartooltip {
      padding: 8px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffffec;
        filter: blur(0px);
        -webkit-filter: blur(0px);
        z-index: -1;
      }

      .colordot {
        position: relative;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .w_data {
      font-size: 0.75rem;
      display: flex;
      padding: 0 10px 8px;
      align-items: center;

      .w_value {
        font-weight: 800;
        font-size: 0.75rem;
        margin-left: 5px;
      }

      .colordot {
        position: relative;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        margin-right: 5px;
      }

      .totaldot {
        position: relative;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: #333;
      }
    }
  }

  .legend_w {
    .apexcharts-legend {
      margin-top: 0 !important;
    }

    .apexcharts-legend-series {
      display: flex;
      align-items: center;
      margin-bottom: 10px !important;
    }
  }

  .chart_tool {
    .apexcharts-legend {
      margin-top: 20px;
    }

    .apexcharts-legend-marker {
      width: 8px !important;
      height: 32px !important;
      margin-right: 8px;
    }

    .legenddata {
      flex-direction: column;

      .datapoint {
        font-size: 0.75rem;
        font-weight: 800;
        margin-bottom: 2px;
      }
    }

    .apexcharts-tooltip {
      background: transparent !important;
      border: 0 !important;
    }

    .wtooltip {
      padding: 8px;
      font-size: 0.75rem;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffffcb;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        z-index: -1;
      }

      .colordot {
        position: relative;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 5px;
      }

      .w_value {
        font-weight: 800;
        font-size: 0.75rem;
        margin-left: 5px;
      }
    }
  }

  .legend_left {
    .apexcharts-legend {
      max-height: 99%;
      overflow-x: hidden;
    }

    .apexcharts-legend {
      display: flex;

      .apexcharts-legend-series {
        display: flex;
        align-items: center;

        .apexcharts-legend-marker {
          width: 8px !important;
          height: 8px !important;
          margin-right: 8px;
        }

        .legenddata {
          flex-direction: row-reverse;
          display: flex;

          .datapoint {
            margin-left: 7px;
          }
        }
      }
    }
  }

  .variation {
    margin: 10px 0 2px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-family: "Manrope", sans-serif !important;

    .colorlabel {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 800;

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0 8px;
      }
    }
  }

  .tableContainer {
    padding: 0px;
  }
}

.r_component {
  .table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    height: 350px;
  }

  .table-scroll table {
    width: 100%;
    min-width: 1280px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }

  .table-wrap {
    position: relative;
  }

  .table-scroll th,
  .table-scroll td {
    padding: 5px 10px;
    border: 1px solid #000;
    background: #fff;
    vertical-align: top;
  }

  .table-scroll thead th {
    background: #333;
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  /* safari and ios need the tfoot itself to be position:sticky also */
  .table-scroll tfoot,
  .table-scroll tfoot th,
  .table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #666;
    color: #fff;
    z-index: 1;
  }

  a:focus {
    background: red;
  }

  /* testing links*/

  th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #ccc;
  }

  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }

  .me_table {
    max-width: 100%;


    .cell {
      font-size: 0.75rem;
      white-space: nowrap;
    }

    .stickycellleft {
      left: 0;
      z-index: 100;
      position: sticky;
      text-align: left;
      background: #fff;
    }

    thead {
      tr {
        border-bottom: 1px solid $border-color;
      }

      th {
        position: sticky;
        top: 0;
        background-color: $sidebar-bg;
        padding: 6px 15px;
        line-height: 22px;
        border-right: 1px solid $border-color;
        font-size: 0.8rem;
        font-weight: 600;

        .datecoleps {
          padding: 15px;
          display: flex;
          flex-direction: column;
          margin: -20px -30px;

          .date {
            padding: 6px 15px;
          }

          .inoutdiv {
            display: flex;
            align-items: center;
            border-top: 1px solid $border-color;
            font-weight: 500;
            opacity: 0.8;

            .in {
              border-right: 1px solid $border-color;
              width: 90px;
              padding: 2px 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .out {
              width: 90px;
              padding: 2px 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        &.stickylslidenumber {
          min-width: 50px;
          height: 49px;
          left: 0;
          z-index: 2;
          position: sticky;
          text-align: center;
        }

        &.timelinestickylslidenumber {
          min-width: 120px;
          height: 50px;
          left: 0;
          z-index: 2;
          position: sticky;
          text-align: center;
        }

        .stickyheademp {
          background: transparent;
          min-width: 230px;
          font-size: 0.85rem;
          text-align: left;
          line-height: 0.5rem;
        }

        .stickyhead {
          background: transparent;
          min-width: 133px;
          font-size: 0.85rem;
          text-align: left;
          line-height: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.stickytotal {
          min-width: 120px;
        }
      }
    }

    tbody {
      min-height: 300px;

      tr {
        border-bottom: 1px solid $border-color;
      }

      td {
        overflow: hidden;
        padding: 8px 15px;
        line-height: 22px;
        border-right: 1px solid $border-color;
        font-size: 0.8rem;
        font-weight: 600;
        text-align: center;

        .datecoleps {
          display: flex;
          flex-direction: column;
          margin: -20px -20px;
          background-color: $sucess-opc;
          font-weight: 600;
          color: $sucess-bg;

          .inoutdiv {
            display: flex;
            align-items: center;
            justify-content: center;

            .in {
              border-right: 1px solid rgba(0, 0, 0, 0.15);
              display: flex;
              align-items: center;
              width: 90px;
              height: 50px;
              justify-content: center;
            }

            .out {
              display: flex;
              align-items: center;
              width: 90px;
              justify-content: center;
              height: 50px;
            }
          }
        }

        .ant-avatar {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .absent {
          background-color: $danger-ops;
          color: $danger-bg;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -15px;
          padding: 20px;
        }

        .duration {
          background-color: $sucess-opc;
          color: $sucess-bg;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -15px;
          padding: 15px;
        }

        .leave {
          background-color: $primary-bg-op;
          color: $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -15px;
          padding: 20px;
        }
      }
    }
  }
}

.number_stats {
  // width: 350px;
  padding: 15px 20px;

  @include theme() {
    background-color: theme-get("$white-bg");
  }

  // border: 1px solid $border-color;
  box-shadow: $box_shadow;
  border-radius: 6px;
  width: 100%;

  .number_block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.in {
      align-items: flex-start !important;

      svg {
        color: $text-color;
        opacity: 0.5;
        font-size: 18px;
        transition: all ease-in-out 600ms;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .n_label {
      // font-size: 0.75rem;
      // font-weight: 600;
      margin-bottom: 10px;
    }

    .n_value {
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 2rem;
    }

    .sn_value {
      .ant-typography {
        font-size: 1.6rem;
        font-weight: 800;
        line-height: 2rem;
        text-transform: lowercase;
      }
    }
  }

  .n_less {
    // font-size: 0.75rem;
    // font-weight: 600;
    margin-top: 10px;

    span {
      color: $danger-bg;
      font-weight: 700;
      margin-right: 5px;
    }
  }

  .n_more {
    // font-size: 0.75rem;
    // font-weight: 600;
    margin-top: 10px;

    span {
      color: $sucess-bg;
      font-weight: 700;
      margin-right: 5px;
    }
  }

  .n_icon {
    .less_i {
      background-color: $danger-ops;
      color: $danger-bg;
      padding: 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 1.9em;
      }
    }

    .more_i {
      background-color: $sucess-opc;
      color: $sucess-bg;
      padding: 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 1.9em;
      }
    }
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
}

.mgrid {
  display: grid;
  gap: 20px;

  border: 0px solid #f00;

  &.column_m2 {
    grid-template-columns: 360px 1fr;
  }

  &.column_2 {
    grid-template-columns: 1fr 1fr;
  }

  &.column_3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.column_4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &.column_5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  &.column_3by2 {
    grid-template-columns: 35% 1fr;
  }
}

.modal_table {
  width: calc(100vw - 669px);
}

.up_b {
  text-align: center;

  .toggle {
    margin-top: 2rem;
    color: hsl(234, 14%, 74%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toggle-btn {
    margin: 0 1rem;
  }

  .checkbox {
    display: none;
  }

  .sub {
    cursor: pointer;
    background: linear-gradient(135deg,
        rgba(163, 168, 240, 1) 0%,
        rgba(105, 111, 221, 1) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* height: 25px;
        width: 50px; */
    height: 1.6rem;
    width: 3.3rem;
    border-radius: 1.6rem;
    padding: 0.3rem 0.1rem;
  }

  .circle {
    background-color: #fff;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
  }

  .checkbox:checked+.sub {
    justify-content: flex-end;
  }
}

.upgrad_block {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .shadow {
    box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.1);
  }

  .popular {
    border: 3px solid $primary;
    position: relative;
    border-radius: 8px;
    background-color: $primary;
    padding: 4px;
    margin-top: -25px;

    .p_text {
      margin-bottom: 5px;
      color: $white-bg;
      font-weight: 700;
      text-transform: uppercase;
    }

    //         &::before{
    //             content: 'popular';
    //             position: absolute;
    //             padding: 2px;
    //             background-color: $primary;
    //  font-weight: 600;
    //             color: $white-bg;
    //             width: 100%; left: 0; top: 0; text-transform: capitalize;
    //         }
  }

  .upgrad_box {
    width: fit-content;
    display: flex;
    align-items: center;

    .price_t {
      min-width: 320px;
      max-width: 320px;
      position: relative;
      background-color: #ffffff;
      border: 0px solid rgba(0, 0, 0, 0.15);
      // box-shadow: 0 0px 15px -10px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      margin: 15px;
      padding: 20px;

      .f_sw {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      h3 {
        font-weight: 800;
      }

      h5 {
        font-weight: 800;
      }

      .txt {
        width: 60%;
        text-align: center;
        opacity: 0.7;
        margin: 0 auto;
      }

      .price {
        margin: 25px 0;

        .price_txt {
          font-size: 3.5rem;
          font-weight: 700;
          display: inline-grid;
          position: relative;
          line-height: 50px;

          span {
            font-size: 1.3rem;
            line-height: 50px;
            opacity: 0.6;
            font-weight: 600;
            left: -15px;
            position: absolute;
            top: -8px;
          }
        }

        .p_user {
          opacity: 0.65;
          margin-top: 5px;
        }
      }
    }
  }

  .free_join {
    height: 40px;

    &:hover {
      color: $text-color;
      font-weight: 700;
      border: 2px solid;
    }
  }

  .sub_btn {
    height: 40px;
  }

  .feature_p {
    margin: 30px 0 20px;
    padding: 0;

    li {
      list-style: none;
      text-align: left;
      padding: 6px 0 6px 20px;

      &.check {
        font-weight: 600;
        display: flex;

        svg {
          background-color: $primaryopi;
          color: $primary;
          font-size: 22px;
          width: 28px;
          height: 28px;
          padding: 4px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      &.uncheck {
        font-weight: 600;
        opacity: 0.3;
        display: flex;

        svg {
          background-color: $overlay;
          color: $text-color;
          font-size: 22px;
          width: 28px;
          height: 28px;
          padding: 4px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

.attendCalender {
  .ant-radio-group {
    display: none;
  }

  &.userdetailcalender {
    .ant-picker-date-panel {
      border: 1px solid $border-light;
    }

    .ant-picker-calendar-header {
      display: none;
    }

    .events {
      width: 150px;
    }

    .ant-badge-status-dot {
      width: 180px !important;
      height: 45px !important;
      border-radius: 0;
      z-index: -1;
    }

    tbody {
      td {
        height: 40px !important;
      }
    }
  }

  .ant-picker-body {
    padding: 0 !important;
    // font-family: "Manrope", sans-serif !important;

    thead {
      th {
        width: 36px;
        height: 36px;
        background-color: $sidebar-bg;
        border-bottom: 1px solid $border-light;
        border-right: 1px solid $border-light;
        font-weight: 700 !important;
        font-size: 0.76rem;

        &:last-child {
          border-right: 0px;
        }
      }
    }

    tbody {
      td {
        width: 36px;
        height: 45px;
        padding: 0 !important;
        position: relative;
        overflow: hidden;
        border-bottom: 1px solid $border-light;
        border-right: 1px solid $border-light;
        font-weight: 700 !important;
        font-size: 0.76rem;

        &.ant-picker-cell-today {
          .ant-picker-cell-inner {
            background: transparent !important;
            color: unset !important;

            &::before {
              border: 0 !important;
            }
          }
        }

        &.ant-picker-cell-selected {
          .ant-picker-cell-inner {
            background: transparent !important;
            color: unset !important;

            &::before {
              border: 0 !important;
            }
          }
        }

        &:last-child {
          border-right: 0px;
        }

        .ant-picker-cell-inner {
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          min-width: 100% !important;
          height: 100% !important;
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        .ant-picker-calendar-date-content {
          position: absolute;
          top: 0;
          left: 0;
          height: 24px;
          width: 24px;
        }

        .events {
          position: absolute;
          margin: 0;
          padding: 0;
          height: unset;

          &.selected {
            .ant-badge-status-present {
              background-color: #7b61ff !important;
            }
          }

          li {
            .ant-badge {
              height: unset;

              .ant-badge-status-present {
                background-color: #14b8a6;
              }

              .ant-badge-status-absent {
                background-color: #ff647c;
              }

              .ant-badge-status-weekday {
                background-color: $blue-color;
              }

              .ant-badge-status-healthy {
                background-color: #7b61ff;
              }

              .ant-badge-status-overburdened {
                background-color: #ffb800;
              }

              .ant-badge-status-underutilized {
                background-color: #d5d4db;
              }

              .ant-badge-status-dot {
                width: 65px;
                height: 55px;
                border-radius: 0;
                z-index: -1;
              }
            }

            height: unset;

            &::marker {
              content: "";
            }
          }
        }

        tr {
          &:last-child {
            td {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

.download-disable {
  cursor: not-allowed;
  opacity: 0.6;
  filter: grayscale(1);

  &:hover {
    filter: grayscale(1);
    opacity: 0.2;
  }

  a {
    cursor: not-allowed;
  }
}