@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

$header-color: #ebece8;
$border-color: rgba(0, 0, 0, 0.15);
$border-light: #f0f0f0;
$sidebar-bg: #f9fafc;
$text-color: #2d2d2d;
$text-hovercolor: #000000;
$hover-bg: rgba(0, 0, 0, 0.02);
$white-bg: #ffffff;
$sidebar-background: #0e082e;
$grey_bg: #f0f0f0;
$primary: #7b61ff;
$primaryopi: #e1dcfa;
$primary-color: #ffb800;
$primary-bg-op: #ff8c0023;
$overlay: #dededec0;
$blue-color: #0084ff;
$blue-bg-op: #0084ff23;
$sucess-bg: #14b8a6;
$sucess-opc: #14b8a61a;
$danger-bg: #ff647c;
$danger-ops: #ff647c1a;
$box_shadow: 0 0px 15px -10px rgba(0, 0, 0, 0.25);
$switch-high: #fff;
$swicth_light: #e1dcfa;
$swtich-text-color: #7b61ff;
$selection: #f0f0f005;
$active: #e1dcfa;
$dropdown-bg: #fff;
$both-white: #fff;

$hover: #f0f0f090;

$header-color-dark: #f60f60;
$border-color-dark: #0e082e;
$border-light-dark: #0e082e;
$sidebar-bg-dark: #0b0720;
$text-color-dark: #fff;
$text-hovercolor-dark: #000000;
$hover-bg-dark: rgba(0, 0, 0, 0.02);
$white-bg-dark: #251f46;
$sidebar-background-dark: #0e082e;
$grey_bg-dark: #f0f0f0;
$primary-dark: #7b61ff;
$primaryopi-dark: #e1dcfa;
$primary-color-dark: #ffb800;
$primary-bg-op-dark: #ff8c0023;
$overlay-dark: #0c062ac0;
$blue-color-dark: #0084ff;
$blue-bg-op-dark: #0084ff23;
$sucess-bg-dark: #14b8a6;
$sucess-opc-dark: #14b8a61a;
$danger-bg-dark: #ff647c;
$danger-ops-dark: #ff647c1a;
$box_shadow-dark: 0 0px 15px -10px rgba(0, 0, 0, 0.25);
$switch-high-dark: #7b61ff;
$swicth_light-dark: #251f46;
$swtich-text-color-dark: #fff;
$selection-dark: #251f46;
$active-dark: #7b61ff;
$dropdown-bg-dark: #0e082e;
$both-white-dark: #fff;
$hover-dark: #0e082e55;
