@import "./../../color.scss";

.upgrad_block {
    .ant-btn {
        justify-content: center;
    }


}

.up_b {
    .ant-segmented {
        background-color: $swicth_light;
        color: $swtich-text-color;

        .ant-segmented-item-selected {
            color: $swtich-text-color;
            font-weight: 600;
        }
    }
}